import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { StatusLabel } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import ExternalLink from '../../../components/ExternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "common-helsinki-cookies",
      "style": {
        "position": "relative"
      }
    }}>{`Common Helsinki cookies`}<a parentName="h1" {...{
        "href": "#common-helsinki-cookies",
        "aria-label": "common helsinki cookies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <StatusLabel type="alert" mdxType="StatusLabel">WIP</StatusLabel>
    <LeadParagraph mdxType="LeadParagraph">
  Using common Helsinki cookie consent brings the benefit of sharing consents. This is useful both for the user and the
  service. The user only has to give their consent once and it becomes easier for the service to manage its cookies.
    </LeadParagraph>
    <h2 {...{
      "id": "key-principles-of-using-common-cookies---you-can-use-common-helsinki-cookies-if-your-service-is-using-the-helfi-domain",
      "style": {
        "position": "relative"
      }
    }}>{`Key principles of using common cookies - You can use common Helsinki cookies if your service is using the `}{`*`}{`.hel.fi domain.`}<a parentName="h2" {...{
        "href": "#key-principles-of-using-common-cookies---you-can-use-common-helsinki-cookies-if-your-service-is-using-the-helfi-domain",
        "aria-label": "key principles of using common cookies   you can use common helsinki cookies if your service is using the helfi domain permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">{`When common cookies are available, use them instead of creating custom cookies. This way the user only has to give their
consent once between multiple Helsinki services. - When a common cookie is used, it must be used `}<strong parentName="li">{`exactly`}</strong>{` as described
on this page. If you modify the cookie in any way, it is no longer a common cookie. - If your service is using custom cookies
alongside common cookies, you still need to ask for consent for the custom cookies (if they are considered optional). - You
can either use all or a part of the common cookies. - Note, that some of the services (e.g. Matomo) may require multiple
common cookies for it to work.`}</li>
    </ul>
    <h2 {...{
      "id": "common-cookies-between-helfi-sites",
      "style": {
        "position": "relative"
      }
    }}>{`Common cookies between `}{`*`}{`.hel.fi sites`}<a parentName="h2" {...{
        "href": "#common-cookies-between-helfi-sites",
        "aria-label": "common cookies between helfi sites permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The following is a list of approved common cookies between services using `}{`*`}{`.hel.fi domain. You can find this list as a JSON object in the `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/cookieConsent/content.json" openInNewTab openInNewTabAriaLabel="Opens in a new tab" openInExternalDomainAriaLabel="Opens a different website" mdxType="ExternalLink">{`Helsinki Design System GitHub repository`}</ExternalLink>{`.`}</p>
    <h3 {...{
      "id": "general",
      "style": {
        "position": "relative"
      }
    }}>{`General`}<a parentName="h3" {...{
        "href": "#general",
        "aria-label": "general permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`General cookies are part of the `}<strong parentName="p">{`Preferences`}</strong>{` category.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie set by`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose of use`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Period of validity`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`i18n-language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hel.fi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stores the user's selected language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1826 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Common preference cookies between *.hel.fi domain services]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "matomo",
      "style": {
        "position": "relative"
      }
    }}>{`Matomo`}<a parentName="h3" {...{
        "href": "#matomo",
        "aria-label": "matomo permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Matomo cookies are part of the `}<strong parentName="p">{`Analytics`}</strong>{` category.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookie set by`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose of use`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Period of validity`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`matomo-page-loads`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hel.fi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tracking page loads`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`393 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`matomo-events`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hel.fi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tracking what the user does in the user interface`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`393 days`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Common Matomo cookies between *.hel.fi domain services]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      